
// Handles the TopBar component and all *UI page components* width (eq Home.js)
.UiQuery {
    margin: 0 auto;
}
@media screen and (min-width: 1000px) {
    .UiQuery {
      width: calc(1000px - 48px);
    }
}
@media screen and (max-width: 1000px) {
    .UiQuery {
      width: calc(100% - 48px);
    }
}